import { handleRouteChangesOnClick } from '@/utils/handleRouteChangesOnClick';
import { FOOTER_STORE_POLICIES_PAGE_TYPE } from '@/utils/constants';
import { PATH } from '@/utils/routes';
import store from '../../../../../redux/store';
export const getStoreLogo = (storeContent, handleLogoClick) =>
  storeContent?.content?.storeDetails?.store_logo_url ? (
    <img
      className="store_logo_image"
      src={storeContent.content?.storeDetails?.store_logo_url}
      alt="store_logo"
      width={
        storeContent?.content?.storeDetails?.store_logo_size
          ? `${parseInt(storeContent.content?.storeDetails?.store_logo_size)}px`
          : '0px'
      }
      height={
        storeContent?.content?.storeDetails?.store_logo_size
          ? `${parseInt(storeContent.content?.storeDetails?.store_logo_size)}px`
          : '0px'
      }
      onError={(e) => (e.target.src = storeContent.content?.storeDetails?.store_logo_url)}
      onClick={handleLogoClick}
      style={{ cursor: 'pointer' }}
      loading="lazy"
    />
  ) : (
    <div className="store_logo_name">
      {store.getState().storeReducer.store.store_name}
    </div>
  );
export const getSpecificPageVisibleItems = (footerContent) => (
  <div className="privacy-terms-container">
    {footerContent?.content?.pageDetailsVisibility?.privacyPolicy && (
      <div
        className="privacy-policies"
        onClick={() =>
          handleRouteChangesOnClick(PATH.STORE_POLICIES, {
            page_type: FOOTER_STORE_POLICIES_PAGE_TYPE?.POLICY,
          })
        }
        style={{ cursor: 'pointer' }}
      >
        Privacy Policy
      </div>
    )}
    {footerContent?.content?.pageDetailsVisibility?.termsAndConditions && (
      <div
        className="terms-conditions"
        onClick={() =>
          handleRouteChangesOnClick(PATH.STORE_POLICIES, {
            page_type: FOOTER_STORE_POLICIES_PAGE_TYPE?.TNC,
          })
        }
        style={{ cursor: 'pointer' }}
      >
        Terms & Conditions
      </div>
    )}
  </div>
);
export const getAddressDetails = (footerContent) => (
  <div className="addressContainer">
    {(footerContent?.content?.storeDetails?.address ||
      footerContent?.content?.storeDetails?.phone ||
      footerContent?.content?.storeDetails?.email) && (
      <div className="address">
        {footerContent?.content?.storeDetails?.address}{' '}
        <div className="contact">
          {footerContent?.content?.storeDetails?.phone && (
            <>Phone {footerContent?.content?.storeDetails?.phone}</>
          )}{' '}
          {footerContent?.content?.storeDetails?.email && <>&nbsp;| &nbsp;</>}{' '}
          {footerContent?.content?.storeDetails?.email && (
            <>Email {footerContent?.content?.storeDetails?.email}</>
          )}{' '}
        </div>
      </div>
    )}
  </div>
);
export const getActiveSocialMedia = (store) => (
  <div className="social-media-section" style={{ gap: '16px' }}>
    {store?.social_media_profile_details?.media_detail?.map((profile) => {
      return profile.profile_url !== '' ? (
        <div
          key={profile.id}
          className="social-media-icon"
          style={{ height: '24px', width: '24px' }}
        >
          <a href={profile.profile_url} target="_blank" rel="noreferrer">
            <img src={profile.image_url} alt={`${profile?.name} image`} />
          </a>
        </div>
      ) : null;
    })}
  </div>
);
export const getFooterRightReservedtext = () => {
  const currentYear = new Date().getFullYear();
  const storeData = store.getState().storeReducer.store;
  const footerConfig = storeData?.theme?.components?.Footer?.[0]?.config;

  return (
    <div className="footer-rights-container">
      <div className="copyright">
        © {storeData.store_name || 'Brand Name'}. All Rights Reserved {currentYear}
      </div>
      {!footerConfig?.hide_ds_label && (
        <div className="copyright-sub-text">Website powered by Digital Showroom</div>
      )}
    </div>
  );
};

export const getDescription = (footerContent) => {
  return (
    footerContent?.description?.text && (
      <div className="footer-description">{footerContent?.description?.text}</div>
    )
  );
};
