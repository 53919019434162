import {
  getSpecificPageVisibleItems,
  getStoreLogo,
  getAddressDetails,
  getActiveSocialMedia,
  getFooterRightReservedtext,
} from '../../utils/commonGetterFunction';
import { FooterMobileContainer } from '../Footer2.styled';

const FooterMobile = (props) => {
  const { footerState, store, propStyle, handleLogoClick } = props;
  return (
    <FooterMobileContainer themeBackground={propStyle} className="footerMobileContainer">
      <div className="first-container">
        {getSpecificPageVisibleItems(footerState?.footerElements)}
      </div>
      <div className="second-container">{getActiveSocialMedia(store)}</div>
      <div className="third-container">
        {getAddressDetails(footerState?.footerElements)}
      </div>
      <div className="fourth-container">
        {getStoreLogo(footerState?.footerElements, handleLogoClick)}
      </div>
      <div className="fifth-container">{getFooterRightReservedtext()}</div>
    </FooterMobileContainer>
  );
};

export default FooterMobile;
