import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { transformWidgetProps } from '@/components/WidgetMaker/utils/common';
import { getStyleForProps } from '@/components/DesignSystem/ModernTheme/utils/getStylesConfig';
import { FooterContainer } from './Footer2.styled';

import FooterDesktop from './Components/FooterDesktop';
import FooterMobile from './Components/FooterMobile';
import { useRouter } from 'next/router';
import { PATH, getRoute } from '@/utils/routes';
import { useSSRSelector } from '@/redux/ssrStore';

const Footer2 = (props) => {
  const { isAlreadyTransformed } = props;

  const [footerState, setFooterState] = useState(
    isAlreadyTransformed
      ? props.footerState
      : transformWidgetProps(props?.config?.children)
  );

  useEffect(() => {
    setFooterState(
      isAlreadyTransformed
        ? props.footerState
        : transformWidgetProps(props?.config?.children)
    );
  }, [props]);

  const { storeData } = useSSRSelector((state) => ({
    storeData: state.storeReducer.store,
  }));
  const { widgetData } = useSelector((state) => ({
    widgetData: state.widgetsReducer,
  }));
  const [propStyle, setPropStyle] = useState(storeData?.theme?.style_template);

  useEffect(() => {
    if (widgetData?.activeStyleTemplate) {
      setPropStyle(getStyleForProps(widgetData?.activeStyleTemplate));
    } else {
      setPropStyle(getStyleForProps(storeData?.theme?.style_template));
    }
  }, [widgetData?.activeStyleTemplate, storeData?.theme?.style_template]);

  const router = useRouter();
  const handleLogoClick = () =>
    router.push(getRoute(PATH.PRODUCT, storeData?.store_info?.domain));
  const [IS_DESKTOP, setIS_DESKTOP] = useState(true);

  const checkWindowSize = () => {
    if (typeof window !== 'undefined') {
      const isMobile = window.innerWidth < 768; // You can adjust this threshold
      setIS_DESKTOP(!isMobile);
    }
  };

  useEffect(() => {
    // Check window size on component mount
    checkWindowSize();

    // Add event listener for window resize only on the client side
    const handleResize = () => {
      checkWindowSize();
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);

      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  return (
    <FooterContainer
      id="Footer2"
      propsBackground={footerState?.media?.background}
      themeBackground={propStyle}
      className={`modern_theme_footer2 bg_type_solid`}
    >
      {IS_DESKTOP ? (
        <FooterDesktop
          store={storeData}
          footerState={footerState}
          setFooterState={setFooterState}
          propStyle={propStyle}
          handleLogoClick={handleLogoClick}
        />
      ) : (
        <FooterMobile
          store={storeData}
          footerState={footerState}
          setFooterState={setFooterState}
          propStyle={propStyle}
          handleLogoClick={handleLogoClick}
        />
      )}
    </FooterContainer>
  );
};
export default Footer2;
